import React from 'react'
import Layout from '../components/Layout'
import { Flex, Box, Text, Image } from '../lib/design-system'
import logo from '../assets/images/supercomponent-white-full-logo.svg'

const IndexPage = () => (
  <Layout>
    <Box p={[4, 5]}>
      <Box mb={5}>
        <Image
          src={logo}
          width={['220px', '256px']}
          alt="Supercomponent Logo"
        />
      </Box>
      <Text mb={3}>Web and mobile app development studio based in Paris.</Text>
      <Flex>
        <Text
          color="#0055FF"
          // css={{
          //   backgroundImage:
          //     'linear-gradient(90deg,  #482475, #414487, #355f8d, #2a788e, #21908d, #22a884, #42be71, #7ad151, #bddf26, #bddf26)',
          //   ' -webkit-background-clip': 'text',
          //   '-webkit-text-fill-color': 'transparent',
          // }}
          onClick={() => {
            navigator.permissions
              .query({ name: 'clipboard-write' })
              .then(result => {
                if (result.state == 'granted' || result.state == 'prompt') {
                  navigator.clipboard
                    .writeText('contact@supercomponent.co')
                    .then(
                      function() {
                        console.log('copied')
                        /* clipboard successfully set */
                      },
                      function() {
                        /* clipboard write failed */
                      }
                    )
                }
              })
          }}
        >
          contact@supercomponent.co
        </Text>
      </Flex>
    </Box>
  </Layout>
)

export default IndexPage
