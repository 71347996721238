import { createGlobalStyle } from 'styled-components'
import IBMPlexMonoRegular from '../assets/fonts/IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Regular.woff2'

export default createGlobalStyle`

    body {
      margin: 0;
      background: black;
      color: white;
      line-height: 1.5;
      font-family: 'IBM Plex Mono', 'Inter UI',  sans-serif;
      font-feature-settings: "onum" 1, "pnum" 1, "kern" 1, "ss03" 1;
    }
  
    @font-face {
      font-family: 'IBM Plex Mono';
      font-style:  normal;
      font-weight: 400;
      font-display: auto;
      src: url(${IBMPlexMonoRegular});
    }

`
